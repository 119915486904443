<template>
  <a-modal :title="title" :visible="show" @cancel="cancel" :destroyOnClose="true" :footer="null" width="30%" :maskClosable="false">
    <a-form-model ref="ruleForm" :layout="formLayout" :rules="rules" :model="form" :labelCol="{ span: 6 }" :wrapperCol="{ span: 14 }">
      <a-form-model-item label="榜单项目："> {{ projectName }} </a-form-model-item>
      <a-form-model-item label="类型：" prop="contentType">
        <a-radio-group :default-value="1" v-model="form.contentType" @change="changeContentType">
          <a-radio :value="help.ContentTypeEnum.article"> {{ help.ContentTypeTextEnmu[help.ContentTypeEnum.article] }}
          </a-radio>
          <a-radio :value="help.ContentTypeEnum.commodity"> {{ help.ContentTypeTextEnmu[help.ContentTypeEnum.commodity] }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="idText" prop="commonId">
        <a-input placeholder="请输入" v-model="form.commonId" />
      </a-form-model-item>
      <a-form-model-item label="描述：" v-if="form.contentType === 2">
        <a-input placeholder="请输入" v-model="form.des" :maxLength="20" />
      </a-form-model-item>
      <a-form-model-item label="优先级：" prop="priority">
        <a-input placeholder="请输入1~9999,优先级越小展示越靠前" v-model.trim="form.priority"
          @input="(e) => (form.priority = validNumKeyBoard(e))" :maxLength="4" />
      </a-form-model-item>
      <div style="width: 100%; display: flex; justify-content: space-around;">
        <a-button type="primary" @click="save"> 保存 </a-button>
        <a-button @click="cancel"> 取消 </a-button>
      </div>
    </a-form-model>
  </a-modal>
</template>
  
<script>
import help from '../help/inxex';
import { validNumKeyBoard } from '@/utils/inputVerify';
import * as api from "@/api/ranking";
export default {
  props: {
    actionType: {
      type: String,
      default: () => 'add'
    },
    show: {
      type: Boolean,
      default: () => false
    },
    projectId: {
      type: String,
      default: () => ''
    },
    projectName: {
      type: String,
      default: () => ''
    },
    id: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    title() {
      return this.actionType === 'add' ? '添加' : '编辑'
    },
    idText() {
      if (this.form.contentType === help.ContentTypeEnum.article) {
        return '帖子ID'
      } else if (this.form.contentType === help.ContentTypeEnum.commodity) {
        return '商品ID'
      }
    }
  },
  watch: {
    projectId: {
      handler(newVal) {
        if (newVal) {
          this.form.projectId = newVal;
        }
      },
      immediate: true
    },
    id: {
      handler(newVal) {
        if (newVal && this.actionType === 'edit') {
          this.getDetail(newVal);
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      formLayout: 'horizontal',
      validNumKeyBoard,
      help,
      //提交表单
      form: {
        contentType: 1,
        commonId: '',
        priority: undefined,
        des: '',
        id: undefined,
        projectId: undefined
      },
      rules: {
        contentType: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        commonId: [
          { required: true, message: '请输入帖子ID', trigger: 'blur' }
        ],
        priority: [
          { required: true, message: '请输入优先级', trigger: 'blur' }
        ],
        commonId: [
          { required: true, message: '请输入ID', trigger: 'blur' }
        ],
      }
    };
  },
  methods: {
    // 取消
    cancel() {
      this.$emit("close")
    },
    // 提交
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.actionType === 'add') {
            this.add();
          } else if (this.actionType === 'edit') {
            this.edit();
          }
        }
      });
    },
    changeContentType() {
      this.form.commonId = '';
      this.form.des = '';
    },
    /**网络请求 */
    // 添加推荐内容
    async add() {
      try {
        const res = await api.addRecommendContent(this.form);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.cancel();
          this.$emit('refresh')
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error('操作失败，请重试');
      }
    },
    // 查询推荐内容详情
    async getDetail(id) {
      try {
        const res = await api.findRecommendContentDetail(id);
        if (res.code === this.$SUCCESS_CODE) {
          const { updateTime, updateUser, stateType, ...form } = res.data;
          this.form = form;
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 编辑推荐内容
    async edit() {
      try {
        const res = await api.editRecommendContent(this.form);
        if (res.code === this.$SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.cancel();
          this.$emit('refresh')
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error)
        this.$message.error('操作失败，请重试');
      }
    }
  },
};
</script>