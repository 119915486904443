// 内容类型枚举
export const ContentTypeEnum = {
  article: 1,
  commodity: 2
}

// 内容类型文案枚举
export const ContentTypeTextEnmu = {
  1: '文章',
  2: '线上商品'
}

// 状态类型文案枚举
export const stateTypeTextEnmu = {
  1: '正常',
  2: '异常'
}

export default {
  ContentTypeTextEnmu,
  stateTypeTextEnmu,
  ContentTypeEnum
}